@import "custom";
@import "fonts/roboto/style.scss";
body {
    font-family: "Roboto", sans-serif;
}

// DropDown
.dropdown-menu {
    box-shadow: $box-shadow-4;
    .dropdown-divider {
        border-top: 1px solid $gray-20;
    }
}

// Buttons
.btn-transparent {
    background: none;
    border: none;
    padding: 0;
}
.hide-caret:after {
    display: none;
}
.dev_mode.show {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 100000;
    top: 0px;
}