@font-face {
    font-family: "Roboto Regular";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Regular"), 
    url("Roboto-Regular.woff2") format("woff2"),
    url("Roboto-Regular.woff") format("woff")
    ;
}

@font-face {
    font-family: "Roboto Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Italic"), 
    url("Roboto-Italic.woff2") format("woff2"),
    url("Roboto-Italic.woff") format("woff");
}

@font-face {
    font-family: "Roboto Bold";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Bold"), 
    url("Roboto-Bold.woff2") format("woff2"),
    url("Roboto-Bold.woff") format("woff");
}

@font-face {
    font-family: "Roboto Bold Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Bold Italic"), 
    url("Roboto-BoldItalic.woff2") format("woff2"),
    url("Roboto-BoldItalic.woff") format("woff");
}

@font-face {
    font-family: "Roboto Thin";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Thin"),
    url("Roboto-Thin.woff2") format("woff2"),
    url("Roboto-Thin.woff") format("woff");
}

@font-face {
    font-family: "Roboto Thin Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Thin Italic"), 
    url("Roboto-ThinItalic.woff2") format("woff2"),
    url("Roboto-ThinItalic.woff") format("woff");
}

@font-face {
    font-family: "Roboto Light";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Light"), 
    url("Roboto-Light.woff2") format("woff2"),
    url("Roboto-Light.woff") format("woff");
}

@font-face {
    font-family: "Roboto Light Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Light Italic"), 
    url("Roboto-LightItalic.woff2") format("woff2"),
    url("Roboto-LightItalic.woff") format("woff");
}

@font-face {
    font-family: "Roboto Medium";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Medium"), 
    url("Roboto-Medium.woff2") format("woff2"),
    url("Roboto-Medium.woff") format("woff");
}

@font-face {
    font-family: "Roboto Medium Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Medium Italic"), 
    url("Roboto-MediumItalic.woff2") format("woff2"),
    url("Roboto-MediumItalic.woff") format("woff");
}

@font-face {
    font-family: "Roboto Black";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Black"), 
    url("Roboto-Black.woff2") format("woff2"),
    url("Roboto-Black.woff") format("woff");
}

@font-face {
    font-family: "Roboto Black Italic";
    font-style: normal;
    font-weight: normal;
    src: local("Roboto Black Italic"), 
    url("Roboto-BlackItalic.woff2") format("woff2"),
    url("Roboto-BlackItalic.woff") format("woff");
}
