// -- Colors
// Gray
$gray-10: #f2f2f7;
$gray-20: #e5e5ea;
$gray-30: #d1d1d6;
$gray-40: #c7c7cc;
$gray-50: #aeaeb2;
$gray-60: #6d7885;
$gray-overlay: rgba(242, 242, 247, 0.6);
// Red
$red-primary: #e61801;
// Black
$black-light: #181d1b;
// White
$simple-white: #fff;
// Green
$green-primary: #28a629;
$green-secondary: #78b617;
// Blue
$blue-primary: #057FF7;
// Box-Shadow
$box-shadow-2 : 0px 0px 2px rgba(0, 0, 0, 0.03), 0px 2px 2px rgba(0, 0, 0, 0.06);
$box-shadow-4 : -2px -2px 4px rgba(0, 0, 0, 0.04), 2px 2px 4px rgba(0, 0, 0, 0.08);
$box-shadow-8 : 0px 0px 4px rgba(0, 0, 0, 0.08), 0px 8px 8px rgba(0, 0, 0, 0.16);
$box-shadow-8s : 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08);
$box-shadow-16 : 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(0, 0, 0, 0.16);
// Error bg
$error-bg: #FAEBEB;