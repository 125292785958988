@import "../../styles/variables";
.wrapper {
    background-image: url(../../images/single-pallet-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 800px;
    height: 100vh;
}

.error-message {
    margin: 300px auto;
    &__title {
        font-size: 32px;
        font-weight: 700;
        color: $black-light;
        text-align: center;
    }
    &__definition {
        font-size: 18px;
        font-weight: 400;
        color: $black-light;
        text-align: center;
    }
}

.home-page {
    color: red;
}