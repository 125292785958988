@import "../../../styles/variables";

.input {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	&__file-wrapper {
		width: max-content;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__file-fake {
		width: 150px;
		text-align: left;
		padding: 5px;
		font-size: 14px;
		border: 1px solid #c7c7c7;
		border-radius: 3px 0 0 3px;
		border-right: none;
	}
	&__file-button {
		width: 100px;
		background-color: $blue-primary;
		padding: 5px;
		color: $simple-white;
		font-size: 14px;
		font-weight: 700;
		border: 1px solid #c7c7c7;
		border-radius: 0 3px 3px 0;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background-color: $gray-50;
		}
	}
}

.wrapper {
	width: max-content;
	position: relative;
	text-align: center;
	margin-bottom: 20px;
}