@import "../../../styles/variables";

.steps {
	background-color: $simple-white;
	padding: 20px;
	border: 1px solid $gray-50;
	border-radius: 0 0 6px 0;
	border-left: none;
	border-top: 0;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.3s ease;
	width: 300px;
	&__button {
		padding: 2px 5px;
		background-color: $blue-primary;
		border: 1px solid $gray-10;
		&_refresh, &_next {
			border-radius: 6px 0 0 6px;
		}
		&_all, &_prev {
			border-radius: 0 6px 6px 0;
		}
	}
	&__img {
		width: 16px;
		height: 16px;
	}
	&__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}
	&__display {
		margin: 0 5px;
		font-size: 16px;
	}
	&__button-open {
		background-color: $gray-40;
		border: none;
		position: absolute;
		top: 0;
		right: -29px;
		border-radius: 0 0 6px 0;
	}
	&__img-open {
		width: 16px;
		height: 16px;
	}
}

.close {
	left: -300px;
}