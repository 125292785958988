.info {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 12px;
	&__id, &__size, &__position, &__sort {
		margin: 0;
		padding: 0;
		margin-top: 5px;
	}
	&__topic {
		font-weight: 600;
		font-size: 14px;
		margin-right: 10px;
	}
}