@import "../../../styles//variables";

.wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
  }
  .lds-dual-ring:after {
	content: " ";
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid $blue-primary;
	border-color: $blue-primary transparent $blue-primary transparent;
	animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  