@import "../../../styles/variables";

.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.3);
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	transition: all 0.3s ease;
	&__window {
		background-color: $simple-white;
		border-radius: 6px;
		padding: 20px;
		display: flex;
		flex-direction: column;
	}
	&__title {
		font-size: 16px;
		font-weight: 600;
		text-align: left;
	}
	&__description {
		font-size: 12px;
		text-align: center;
	}
	&__button {
		display: inline-block;
		border-radius: 6px;
		border: none;
		background-color: $blue-primary;
		color: $simple-white;
		font-size: 14px;
		padding: 5px 10px;
		width: 100%;
		transition: all 0.3s ease;
		&:hover {
			background-color: $gray-50;
		}
	}
}