.input-range {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
	text-decoration: underline;
	margin: 10px;
	&__input {
		width: 100px;
	}
	&__label {
		margin: 0;
	}
}