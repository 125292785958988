@import "../../../styles/variables";

.settings {
	background-color: $simple-white;
	padding: 20px;
	border: 1px solid $gray-50;
	border-radius: 0 0 0 6px;
	border-right: none;
	border-top: 0;
	position: fixed;
	top: 0;
	right: 0;
	width: 300px;
	transition: all 0.3s ease;
	box-sizing: border-box;
	&__button {
		background-color: $gray-40;
		border: none;
		position: absolute;
		top: 0;
		left: -29px;
		border-radius: 0 0 0 6px;
	}
	&__img {
		width: 16px;
		height: 16px;
	}
}


.close {
	right: -300px;
}