@import "_variables";
// Theme
$theme-colors: (
    "danger": $red-primary,
    "success": $green-primary
);
// Table
$table-border-color: $gray-20;
$table-hover-bg: $gray-10;
// DropDown
$dropdown-font-size: 12px;
$dropdown-color: $black-light;
$dropdown-border-width: 0;
$dropdown-border-radius: 6px;
$dropdown-padding-y: 8px;
$dropdown-padding-x: 0px; 
$dropdown-item-padding-y : 5px;
$dropdown-item-padding-x : 9px;
$dropdown-link-hover-bg: $simple-white;
$dropdown-divider-margin-y: 0;
// Buttons
$btn-border-radius: 8px;
$btn-padding-y: 12px;
$btn-padding-x: 16px;
$btn-line-height: 18px;
// Input
$input-bg: $gray-10;
$input-border-width: 0;
$input-box-shadow: none;
$input-placeholder-color: $gray-50;
$input-focus-box-shadow: none;
// Dropdown
$dropdown-link-active-color: inherit;
$dropdown-link-active-bg: inherit;
// Nav (Tabs)
$nav-tabs-link-active-color: $green-primary;
$nav-tabs-link-active-bg: $gray-overlay;
$nav-tabs-border-radius: 0;

@import "~bootstrap/scss/bootstrap";
